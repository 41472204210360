import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ohdservers_platform from './images/ohdservers_platform.png';
import NavBar from './components/NavBar';
import { AiOutlineFieldTime } from 'react-icons/ai';

function About(props) {

  return (
      <div className="overallbg">
            <NavBar />
            <Row style={{margin: '0px', padding: '0px', background: 'white', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
              <Col><h3>About ohdservers.net stack</h3></Col>
            </Row>
            <p></p>
            <Row style={{margin: '0px', padding: '0px'}}>
              <Col md={6} sm={12}>
              <img style={{opacity: 0.9}} src={ohdservers_platform} alt="" width="100%" height="90%"/>
              </Col>
              <Col md={6} sm={12}>
                <AiOutlineFieldTime size="50px" /> 2 minute read.

                <p>
                  ohdservers.net is an <u>unofficial</u> OHD fan project. My intentions, design and presentation are my own
                  and are not affiliated with the official OHD project nor Discord. Used OHD and discord logo's and images belong to their
                  respective owners. 
                </p> 
                <p>
                  ohdservers.net is a platform that provides an overview of online OHD servers and some other features
                  which I hope will add to bring the OHD player community together. A tight integration with Discord should help
                  communities plan cross discord server events, manage gameservers and present the joy of team work through a single platform.
                </p>
                <hr />
                <p>
                  Hi there, my name is <a href="https://www.linkedin.com/in/patrickblaas/" target="_new">Patrick Blaas</a> and in 2022 I decided to become <a href="https://www.patreon.com/drakelinglabs" target="_new">patreon of Operation Harsh Doorstop</a>.
                  This project has a great vision to create a free game which supports steam workshop integration and modding
                  from day 1. The game released in Early access 15 of february 2023.
                </p>
                <hr />
                <p>
                  In anticipation of the GA release I decided to create some applications in 2022 for this game.
                  The stack overview on this page shows all the components which make up the platform which are used
                  by the applications one way or another.
                  If you have any questions about it, let me know on the <a href="https://discord.com/invite/drakelings" target="_new">official O:HD Discord channel.</a> 
                </p>
                <p>All applications in the overview are running in a container which are managed by an Ansible playbook. This makes it easy to deploy the stack to a different location.</p>
                <hr />
                <p>In order to use the quick links you need to add additional information to your servername. The following information is parsed:
                  <ul>
                    <li><u>discord.gg/</u> - Link to discord server.</li>
                    <li><u>dsc.gg/</u> - Link to discord server.</li>
                    <li><u>DSC.GG/</u> - Link to discord server.</li>
                    <li><u>discord.me/</u> - Link to discord server.</li>
                    <li><u>twitch@</u> - Link to twitch page.</li>
                    <li><u>k@</u> - Link to kick.com page.</li>
                    <li><u>ugc/</u> - Mod information managed through the discord bot Magic.</li>
                    
                  </ul>
                </p>
                <p>
                <a href="https://discord.com/invite/drakelings" target="_new">Spec0910 out.</a>
                </p>

              </Col>
            </Row>
            This product includes GeoLite2 data created by MaxMind, available from <a href="https://www.maxmind.com">https://www.maxmind.com</a>.
            
      </div>
  );
}


export default About;