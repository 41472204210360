/* eslint-disable no-script-url */
import React, { useEffect } from 'react';
import { Container } from 'reactstrap'


function ValidateIPaddress(ipaddress) 
{
 if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress))
  {
    return (true)
  }
return (false)
}

const RedirectPage = (serverIp,serverPort) => {
    useEffect(() => {
      window.location.replace(`steam://run/736590//+connect ${serverIp}:${serverPort}`)
    }, [])
  }

function Launcher() {
    const serverIp = window.location.pathname.split('/')[2].split(':')[0];
    const serverPort = window.location.pathname.split('/')[2].split(':')[1];

    if (ValidateIPaddress(serverIp)) {

        return(
            <div className="overallbg">
                <Container className="center-screen">
                  Operation Harsh Doorstop launcher
                  <a style={{color: 'red'}} href="https://ohdservers.net">ohdservers.net</a>
                  <a style={{color: 'red'}} href="https://store.steampowered.com/app/736590/Operation_Harsh_Doorstop/">Buy O:HD on steam</a>
                  <a style={{color: 'red'}} href="https://discord.com/invite/drakelings">O:HD Discord</a>
                  <a href="javascript:history.back()">Back</a>
                </Container>
                {RedirectPage(serverIp,serverPort)}
            </div>
        )
    }
    return null;
};

export default Launcher;