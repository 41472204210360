import React, { useState, useEffect } from 'react';
import { AiOutlineRollback } from 'react-icons/ai';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardText, CardFooter } from 'reactstrap'
import Select, { StylesConfig } from 'react-select';
import Chart from "react-apexcharts";
import chroma from 'chroma-js';
import RingLoader from "react-spinners/RingLoader";
import PlayerComponent from './components/PlayerComponent';
import { FaHeart } from 'react-icons/fa';
import { MdSubscriptions } from 'react-icons/md';
import { FiUploadCloud } from 'react-icons/fi';
import { BsRocketTakeoff } from 'react-icons/bs';

export function epochToDatetime(sec) {
  let d = new Date(sec * 1000); // The 0 there is the key, which sets the date to the epoch
  return d.toLocaleString();
}

function getSteamConnect(ip, port) {
  // return "steam://run/736590//+connect " + ip + ":" + port
  return "https://ohdservers.net/launcher/" + ip + ":" + port
}

function Server(props) {

  const [serverconfig, setserverconfig] = useState('');
  const [loadinganim, setloadinganim] = useState({ "loading": true });
  const [stattime, setstattime] = useState('30day');
  const [darkmode, setdarkmode] = useState(false);

  const apexoptions = {
    options: {
      chart: {
        toolbar: {
          show: false
        }
      },
      tooltip: {
        enabled: true,
        color: '#000',
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: true,
        },
        y: {
          show: false,
        },
        fixed: {
          enabled: true,
          position: 'topLeft',
        },
        style: {
          fontSize: '16px',
        },
      },
      title: {
        text: "Players online last 24 hours",
        align: "center",
        style: {
          color: '#000',
        },
      },
      legend: {
        show: true,
        style: {
          color: '#fff',
        },
      },
      grid: {
        show: true,
      },
      xaxis: {
        type: 'category',
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
          color: '#000000'
        },
      },
      yaxis: {
        type: 'category',
        labels: {
          show: true,
          style: {
            colors: ['black','red'],
          },
        },
        axisTicks: {
          show: false,
          color: '#000'
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors:['#000'],
      fill: {
        colors: ['#000'],
        type: 'pattern',
        pattern: {
          style: 'slantedLines',
          width: 5,
          height: 2,
          strokeWidth: 4,
        },
      },
      markers: {
        colors: ['#000']
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#ffa500e6']
        },
        background: {
          enabled: false,
          foreColor: '#fff',
          padding: 1,
          borderRadius: 2,
          borderWidth: 2,
          borderColor: '#ffa500e6',
          opacity: 1,
        }
      },
    }
  };


  const apex_7day_options = {
    options: {
      chart: {
        toolbar: {
          show: false
        }
      },
      tooltip: {
        enabled: true,
        color: '#000',
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: true,
        },
        y: {
          show: false,
        },
        fixed: {
          enabled: true,
          position: 'topLeft',
        },
        style: {
          fontSize: '16px',
        },
      },
      title: {
        text: "Players online last 7 days",
        align: "center",
        style: {
          color: '#000',
        },
      },
      legend: {
        show: true,
      },
      grid: {
        show: true,
      },
      xaxis: {
        type: 'category',
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: '#000000',
        },
        axisTicks: {
          show: false,
          color: '#000000'
        },
      },
      yaxis: {
        type: 'category',
        labels: {
          show: true,
          style: {
            colors: ['black','red'],
          },
        },
        axisBorder: {
          show: false,
          color: '#000000',
        },
        axisTicks: {
          show: false,
          color: '#000000'
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors:['#000'],
      fill: {
        colors: ['#000'],
        type: 'pattern',
        pattern: {
          style: 'slantedLines',
          width: 5,
          height: 2,
          strokeWidth: 4,
        },
      },
      markers: {
        colors: ['#000']
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#008000']
        },
        background: {
          enabled: true,
          foreColor: '#ffa500e6',
          padding: 1,
          borderRadius: 2,
          borderWidth: 2,
          borderColor: 'white',
          opacity: 1,
        }
      },
    }
  };

  
  const apex_30day_options = {
    options: {
      chart: {
        toolbar: {
          show: false
        }
      },
      tooltip: {
        enabled: true,
        color: '#000000',
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: true,
        },
        y: {
          show: false,
        },
        fixed: {
          enabled: true,
          position: 'topLeft',
        },
        style: {
          fontSize: '16px',
        },
      },
      title: {
        text: "Players online last 30 days",
        align: "center",
        style: {
          color: '#000',
        },
      },
      legend: {
        show: true,
      },
      grid: {
        show: true,
      },
      xaxis: {
        type: 'category',
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: '#000000',
        },
        axisTicks: {
          show: false,
          color: '#000000'
        },
      },
      yaxis: {
        type: 'category',
        labels: {
          show: true,
          style: {
            colors: ['black','red'],
          },
        },
        axisBorder: {
          show: false,
          color: '#000000',
        },
        axisTicks: {
          show: false,
          color: '#000000'
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors:['#000'],
      fill: {
        colors: ['#000'],
        type: 'pattern',
        pattern: {
          style: 'slantedLines',
          width: 5,
          height: 2,
          strokeWidth: 4,
        },
      },
      markers: {
        colors: ['#000']
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#000000']
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 1,
          borderRadius: 2,
          borderWidth: 2,
          borderColor: '#000',
          opacity: 1,
        }
      },
    }
  };

  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });
  
  const colourStyles: StylesConfig<ColourOption> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  const colourOptions = [
    { value: '1day', label: '1 day stats', color: 'black' },
    { value: '7day', label: '7 day stats', color: 'red' },
    { value: '30day', label: '30 day stats', color: 'black' }
  ];
  
  const toggleSelect = (props) => (
    setstattime(props.value)
  )

  const ModSpec = (props) => {
    return(
          <Col sm="12" md="4" xl="2">
          <Card>
              <img
              alt=""
              src={props.previewUrl}
              width="100%"
              height="150px"
              />
              <CardBody className="modcardbody">
                  <CardTitle>
                  {props.title}
                  </CardTitle>
                  {/* <CardSubtitle style={{background: 'black', textAlign: 'center', }}>
                  <a href={'https://steamcommunity.com/sharedfiles/filedetails/?id=' + props.fileId} target="_new" style={{color: 'white'}}>Subscribe</a>
                  </CardSubtitle> */}
                  <CardText>
                  {props.description.length > 70 ? <> {props.description.substring(0,70)}...</> : props.description}
                  </CardText>
    
              </CardBody>
              <CardFooter className="modcardFooter" > 
                <a href={'https://steamcommunity.com/sharedfiles/filedetails/?id=' + props.fileId} target="_new" style={{color: 'black', fontWeight: '600', fontSize: 'medium'}}>Subscribe to mod</a><br />
                <span><FaHeart color="red" /></span><span style={{paddingRight: "5px", fontSize: 'smaller'}}> {props.favorited}</span>
                <span><MdSubscriptions /></span><span style={{paddingRight: "5px", fontSize: 'smaller'}}> {props.subscriptions}</span>
                <span><FiUploadCloud /></span> <span style={{fontSize: 'smaller'}}> {props.lastUpdated.split(",")[0]} </span>
              </CardFooter>
              </Card>
              </Col>
    )
  }

  const b64 = window.location.pathname.split('/')[2]

  const fetchData = async () => {
    setloadinganim({ "loading": true })
    fetch('https://api.ohdservers.net/api/info?q=' + b64)
      .then(response => response.json())
      .then(resultData => {
        setserverconfig(resultData);
        setloadinganim({ "loading": false });
      })
      .catch(error => setserverconfig({ "state": "error", "msg": "Server info could not be retrieved." }))
  }

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, [])

  function isEven(n) {
    if (n % 2 === 0) {
      return "#c59609e0"
    } else {
      return "linear-gradient(-90deg, #c59609e0, #000000)"
    }
  }
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "black",
  };

  
  //console.log(serverconfig)
  return (
    <>
      <div className="overallbg">
          
            <Row style={{margin: '0px', padding: '0px'}}>
              <Col className=""><a href="/" target="_self"><AiOutlineRollback /> Server overview</a></Col>
            </Row>
            {serverconfig && serverconfig.state !== "error" ? (
                <>
                  <Row style={{margin: '5px'}}>
                    <Col lg={3} md={12} xs={12}>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Server</Col><Col xs="auto" className="serverValue">{serverconfig.Info.name}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Network</Col><Col xs="auto" className="serverValue">{serverconfig.Info.sip}:{serverconfig.Info.port}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Password</Col><Col xs="auto" className="serverValue">{serverconfig.Rules.REQUIRESPWD_b === "false" ? "No password required" : "Members only"}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Map</Col><Col xs="auto" className="serverValue">{serverconfig.Info.map}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Mode</Col><Col xs="auto" className="serverValue">{serverconfig.Rules.GameMode_s}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Build</Col><Col xs="auto" className="serverValue">{serverconfig.Info.keywords.split(",")[0].split(":")[1]}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Players</Col><Col xs="auto" className="serverValue">{serverconfig.Info.players} / {serverconfig.Info.max_players}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Bots</Col><Col xs="auto" className="serverValue">{serverconfig.Info.bots}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">OS</Col><Col xs="auto" className="serverValue">{serverconfig.Info.environment === "w" ? "Windows" : "Linux"}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Vac</Col><Col xs="auto" className="serverValue">{serverconfig.Info.vac === 1 ? "Enabled" : "Disabled"}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Visibility</Col><Col xs="auto" className="serverValue">{serverconfig.Info.visibility}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Ping</Col><Col xs="auto" className="serverValue">{(serverconfig.Info._ping).toString().split('.')[0]} ms</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Supporter</Col><Col xs="auto" className="serverValue">{(serverconfig.Rules.Supporter_b).toString().split('.')[0]}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader">Modded</Col><Col xs="auto" className="serverValue">{(serverconfig.Rules.UGC_b).toString().split('.')[0]}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader ">Whitelist</Col><Col xs="auto" className="serverValue">{(typeof(serverconfig.Rules.WHITELIST_i) !== "undefined" && serverconfig.Rules.WHITELIST_i).toString().split('.')[0]}</Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 3, offset: 1}} xs="4" className="serverHeader d-none d-lg-block">Join</Col><Col xs="auto" className="serverValue serverJoinLink pulse block d-none d-lg-block"><span className="no-hover"><a href={getSteamConnect(serverconfig.Info.sip,serverconfig.Info.port)}>Enter the battlefield </a></span></Col>
                      </Row>
                    </Col>

                    <Col lg={9} className="d-none d-md-block">
                        <Col md={{ size: 4, order: 2, offset: 8 }}>
                          <Select
                            onChange={toggleSelect}
                            options={colourOptions}
                            styles={colourStyles}
                          />
                          </Col>
                      
                        { serverconfig && stattime && stattime === "1day" ? 
                          <>
                            <Chart
                              className={serverconfig.Info.map.replace(/[ '.,:()/_]/g, '')}
                              options={apexoptions.options}
                              series={[{"name": "Players", "data": serverconfig.PlayerStats}]}
                              type="line"
                              height="450px"
                            /> </>: <></>
                          }
                          { serverconfig && stattime && stattime === "7day" ? 
                          <>
                          <Chart
                              options={apex_7day_options.options}
                              className={serverconfig.Info.map.replace(/[ '.,:()/_]/g, '')}
                              series={[{"name": "Players", "data": serverconfig.PlayerStats7}]}
                              type="line"
                              height="450px"
                            /> </>: <></>
                          }
                          { serverconfig && stattime && stattime === "30day" ? 
                          <><Chart
                          options={apex_30day_options.options}
                          className={serverconfig.Info.map.replace(/[ '.,:()/_]/g, '')}
                          series={[{"name": "Players", "data": serverconfig.PlayerStats30}]}
                          type="line"
                          height="450px"
                            /> </>: <></>
                          }
                      
                    </Col>

                  </Row>

                </>
              )
              : (
                <>
                <div css={override}>Loading...</div>
                  <RingLoader color="#000" loading={loadinganim} cssOverride={override} size={75} />
                </>
              )}

          <PlayerComponent b64={b64} />
          
          {serverconfig && serverconfig.state !== "error" && serverconfig.Mods.length > 1 ? (
            <> Installed Mods:</>
          ) : <></>
          }
        
          <Row style={{marginLeft: '5px', marginRight: '5px'}}>
          {serverconfig && serverconfig.state !== "error" && serverconfig.Mods.length > 1 ? (
              serverconfig.Mods.map((mod,index) => (
                <ModSpec key={index} title={mod.modlistSpec.title} 
                previewUrl={mod.modlistSpec.previewUrl} subscriptions={mod.modlistSpec.subscriptions} 
                favorited={mod.modlistSpec.favorited} lastUpdated={epochToDatetime(mod.modlistSpec.timeUpdated)} 
                fileId={mod.modlistSpec.fileId} creator={mod.modlistSpec.creator}
                description={mod.modlistSpec.description}
                />
            ))
          ) :
            <></>
          }
          </Row>
          <br />
      </div>
      </>
  );
}


export default Server;