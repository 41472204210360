import React, { useState, useEffect } from 'react';
import NavBar from './components/NavBar'
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardText, CardFooter, Container } from 'reactstrap'
import { FaHeart } from 'react-icons/fa';
import { MdSubscriptions } from 'react-icons/md';
import { FiUploadCloud } from 'react-icons/fi';
export function epochToDatetime(sec) {
    let d = new Date(sec * 1000); // The 0 there is the key, which sets the date to the epoch
    return d.toLocaleString();
}

const ModSpec = (props) => {
    return(
        
        <Card
             style={{
                minWidth: '350px',
                maxWidth: '350px',
                margin: '5px',
                padding: '0px',
                border: 'thick double #32a1ce;'
            }}>
            <img
            alt=""
            src={props.previewUrl}
            width="100%"
            height="200px"
            />
            <CardBody>
                <CardTitle>
                {props.title}
                </CardTitle>
                <CardSubtitle style={{background: 'black', textAlign: 'center', }}>
                <a href={'https://steamcommunity.com/sharedfiles/filedetails/?id=' + props.fileId} target="_new" style={{color: 'white'}}>Subscribe</a>
                </CardSubtitle>
                <CardText>
                {props.description.length > 70 ? <> {props.description.substring(0,70)}...</> : props.description}
                </CardText>
   
            </CardBody>
            <CardFooter className="mb-1 videoFooter" >
              <span><FaHeart color="red" /></span><span> {props.favorited}</span><br />
              <span><MdSubscriptions /></span><span> {props.subscriptions}</span><br />
              <FiUploadCloud /> {props.lastUpdated} CET
            </CardFooter>
            </Card>
    )
  }


function Mods() {
    const [mUrl] = useState(window.location.pathname.split('/')[2]);

    const [serverconfig, setserverconfig] = useState('');
    
    const fetchData = async () => {
      fetch('https://api.ohdservers.net/api/murl?q=' + mUrl)
      //fetch('http://192.168.2.2:8080/api/events')
          .then(response => response.json())
          .then(resultData => {
              // console.log(resultData)
              setserverconfig(resultData)
          })
          .catch(error => ({ "state": "error", "msg": "Server info could not be retrieved." }))
      }
  
      useEffect(() => {
      fetchData();
      }, [])

    console.log(serverconfig)
    return (
        <div className="overallbg">
            <NavBar />
            <Row style={{background: 'white', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                    <Col><h3>Mods</h3></Col>              
            </Row>
            <Row>
                <p>
                    This mod page will show a list of mods registered to a discord guilds mod slot through the discord bot 'Magic' which you can find in the discord section of ohdservers.net.
                </p>
                <p>
                    The following mods are installed on the server:
                </p>
            </Row>
            
            <Container>
            <Row>
            {/* <VideoSpec poster="Spec" guild="Crypto&Gaming" vurl="https://twitch.tv/speccies" summary="Some short text" timestamp="20230201"/>
            <VideoSpec poster="BlueDrake42" guild="OperationHarshDoorstop" vurl="https://www.youtube.com/watch?v=8gespJTaxBM" summary="Release Stream" timestamp="20230201"/> */}
              {serverconfig && serverconfig.state !== "error" && serverconfig.detail !== "Not Found" && serverconfig.length > 0 && serverconfig.map((mod, index) => (
                  <ModSpec key={index} title={mod.modlistSpec.title} 
                  previewUrl={mod.modlistSpec.previewUrl} subscriptions={mod.modlistSpec.subscriptions} 
                  favorited={mod.modlistSpec.favorited} lastUpdated={epochToDatetime(mod.modlistSpec.timeUpdated)} 
                  fileId={mod.modlistSpec.fileId} creator={mod.modlistSpec.creator}
                  description={mod.modlistSpec.description}
                  
                  />
              ))}
            </Row>
            </Container>
        </div>
    )
}

export default Mods;
