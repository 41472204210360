import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import './Appv5.css'
import Server from './Server'
import reportWebVitals from './reportWebVitals'
import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css'
import V6 from './V6'
import Empty from './Empty'
import ServerSetup from './ServerSetup'
import About from './About'
import Mods from './Mods'
import Launcher from './launcher'

const routing = (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<V6 />}></Route>
      <Route path="/empty" element={<Empty />}></Route>
      <Route path="/server/:servername" element={<Server />}></Route>
      <Route path="/launcher/:ip" element={<Launcher />}></Route>
      <Route path="/serversetup" element={<ServerSetup />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/mods/:mUrl" element={<Mods />}></Route>

    </Routes>
  </BrowserRouter>
)

// ReactDOM.render(
//   routing,
//   document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(routing);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
