import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import bookPath from './../Markdown/moddownloader.md'

const ModDownloader = () => {
    const [text, setText] = useState('')

    useEffect(() => {
        fetch(bookPath)
        .then((response) => response.text())
        .then((md) => {
            setText(md)
        })
    }, [])

    return (
        <div className="moddownloader">
            <ReactMarkdown children={text} />
        </div>
    )
}

export default ModDownloader