import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { InputGroup, Button, Input } from 'reactstrap';
import { GrUnlock } from 'react-icons/gr';
import { GrLock } from 'react-icons/gr';
import { FaFilm } from 'react-icons/fa';
import { FaWrench } from 'react-icons/fa';
import { FaRegUser } from 'react-icons/fa';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaRegChartBar } from 'react-icons/fa';
import { MdManageSearch } from 'react-icons/md';
import { BsDiscord } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';
import { MdOutlineBuild } from 'react-icons/md';
import { MdOutlineRememberMe } from 'react-icons/md';
import { MdOutlineSupport } from 'react-icons/md';
import { BsPuzzle } from "react-icons/bs";
import {IconContext} from "react-icons";
import { Tooltip } from 'reactstrap';
import {Buffer} from 'buffer';
import PacmanLoader from "react-spinners/PacmanLoader";
import Flag from 'react-world-flags';
import { RiFileInfoLine } from 'react-icons/ri';
import { TbBrandKick } from "react-icons/tb";


// import useSound from 'use-sound';
// import plop from './sounds/space-click.wav';

function getSteamConnect(ip, port) {
  // return "steam://run/736590//+connect " + ip + ":" + port
  return "https://ohdservers.net/launcher/" + ip + ":" + port
}

function returnBase64(servername) {
  const encodedString = Buffer.from(servername).toString('Base64');
  return encodedString
}

function isEven(n) {
  if (n % 2 === 0) {
    return { 
      "background": '#ffffff10',
      "color": "black",
      "margin": "0px",
      "padding": "0px"
    }
  } else {
    return { 
      "background": '#00000010',
      "color": 'black',
      "margin": "0px",
      "padding": "0px"
     }
  }
}

function Empty(props) {
    const [serverconfig, setserverconfig] = useState('');
    const [loadinganim, setloadinganim] = useState({ "loading": true });
    const [foundServers, setFoundServers] = useState('');
    const [tooltip, setToolTip] = useState(false);
    const [darkMode, setDarkMode] = useState(false);

  
    // const [play] = useSound(plop);


    const tooltipToggle = () => setToolTip(!tooltip);  

    const fetchData = async () => {
      setloadinganim({ "loading": true })
      fetch('https://api.ohdservers.net/api/emptyservers')
      //fetch('http://localhost:8080/api/allservers')
        .then(response => response.json())
        .then(resultData => {
          setserverconfig(resultData);
          setFoundServers(resultData);
          setSearch('');
          setloadinganim({ "loading": false })
        })
        .catch(error => setserverconfig({ "state": "error", "msg": "Server info could not be retrieved. " + error }))
    }

    async function findServerName(server) {
      fetch(`https://api.ohdservers.net/api/findservername?q=${server}`)
      //fetch('http://localhost:8080/api/allservers')
        .then(response => response.json())
        .then(resultData => {
          //setServerFind(resultData);

          setFoundServers(resultData);
          //console.log(resultData);
     })
        .catch(error => setserverconfig({ "state": "error", "msg": "Server info could not be retrieved. " + error }))
    }
  
    useEffect(() => {
      fetchData();
    }, [])
  
    useEffect(() => {
      const interval = setInterval(() => {
        fetchData();
      }, 60000);
      return () => clearInterval(interval);
    }, [])

    // the value of the search field 
    const [search, setSearch] = useState('');  
    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
          const results = serverconfig.filter((s) => {
            return s.name.toLowerCase().includes(keyword.toLowerCase());
            // Use the toLowerCase() method to make it case-insensitive
          });
          if (keyword.length <= 4) {
            setFoundServers(results);
          } else {
            if(keyword.length <= 5) {
              findServerName(keyword);
            }
          }

          //findServerName(keyword);
        } else {
          setFoundServers(serverconfig);
        }
        setSearch(keyword);
      
      };

      const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "black",
      };
    // console.log(ServerFind);
  return (
    <>
      <div className="overallbg">
         
            <InputGroup>
                <a href="/" target="_self">< MdManageSearch size="30px" /></a>
                <Input
                type="search"
                bsSize="sm"
                value={search}
                onChange={filter}
                placeholder="Find your server"
                />
               
                {/* <a className="d-none d-md-block" href="/discord"><Button size="sm" outline color="warning"><FaDiscord size="20px" /></Button></a> */}
                <a className="d-none d-md-block" href="/serversetup"><Button size="sm" outline color="warning"><FaWrench size="20px" /></Button></a>
                {/* <a className="d-none d-md-block" href="/events"><Button size="sm" outline color="warning"><FaRegCalendarAlt size="20px" /></Button></a> */}
                {/* <a className="d-none d-md-block" href="/videos"><Button size="sm" outline color="warning"><FaFilm size="20px" /></Button></a> */}
                {/* <a className="d-none d-md-block" href="/global"><Button size="sm" outline color="warning"><FaRegChartBar size="20px" /></Button></a> */}
                <a className="d-none d-md-block" href="/about"><Button size="sm" outline color="warning"><FaRegUser size="20px" /></Button></a>
                <a className="d-none d-md-block" href="https://store.steampowered.com/app/736590/Operation_Harsh_Doorstop/" target="_new"><Button size="sm" outline color="success">OHD Free</Button></a>
            </InputGroup>

            <Row style={{padding: '0px', margin: '0px'}}>
                <Tooltip placement="right" isOpen={tooltip} target="TooltipJoin" toggle={tooltipToggle}>
                  Click the info icon for server details
                </Tooltip>
                <Col  xl="2" md="2" className="serverHeaderMain d-none d-md-block" id="TooltipJoin">Join</Col>
                <Col  xl="1" md="1" xs="4" sm="4" className="serverHeaderMain">Players</Col>
                <Col  xl="4" md="5" xs="8" sm="8" className="serverHeaderMain">Host</Col>
                <Col  xl="2" md="2" className="serverHeaderMain d-none d-md-block">Mission Area</Col>
                <Col  xl="2" md="2" className="serverHeaderMain d-none d-md-block">Mode</Col>
                <Col  xl="1" className="serverHeaderMain d-none d-xl-block">Build</Col>
            </Row> 
            {foundServers && foundServers.detail !== "not found" && foundServers.length > 0 ? (
                foundServers.map((s,index) => (
                    <Row key={index} className="serverResults" style={isEven(index+1)}>
      
                        <Col lg="2" md="2" className="d-none d-md-block">
                          <a href={/server/ + returnBase64(s.name)} target="_self"> <RiFileInfoLine size="20px" /></a>
                          
                          {/* Kick.com logic */}
                          {s.name.includes('k@') ? (
                                <IconContext.Provider value={{ className: "modded"  }}>
                                  <a style={{marginRight: "1px"}} href={"https://kick.com/" + s.name.split("k@")[1].split(" ")[0]}><TbBrandKick /></a>
                                </IconContext.Provider>
                            ) : <IconContext.Provider value={{ className: "unmodded"  }}><TbBrandKick /> </IconContext.Provider>
                          }   
                          
                          {/* Discord logic */}
                          {s.name.includes('discord.gg/') ? (

                            <IconContext.Provider value={{ className: "discordOn"  }}>
                              <a style={{marginRight: "1px"}} href={"https://discord.gg/" + s.name.split("discord.gg/")[1].split(" ")[0]}><BsDiscord /></a>
                            </IconContext.Provider>

                            ) : s.name.includes('DSC.GG/') ? (

                            <IconContext.Provider value={{ className: "discordOn"  }}>
                              <a style={{marginRight: "1px"}} href={"https://dsc.gg/" + s.name.split("DSC.GG/")[1].split(" ")[0]}><BsDiscord /></a>
                            </IconContext.Provider>

                            ) : s.name.includes('discord.me/') ? (

                              <IconContext.Provider value={{ className: "discordOn"  }}>
                                <a style={{marginRight: "1px"}} href={"https://discord.me/" + s.name.split("discord.me/")[1].split(" ")[0]}><BsDiscord /></a>
                              </IconContext.Provider>

                            ) : s.name.includes('dsc.gg/') ? (

                              <IconContext.Provider value={{ className: "discordOn"  }}>
                                <a style={{marginRight: "1px"}} href={"https://dsc.gg/" + s.name.split("dsc.gg/")[1].split(" ")[0]}><BsDiscord /></a>
                              </IconContext.Provider>

                            ) : s.name.includes('preunk.com/discord') ? (

                              <IconContext.Provider value={{ className: "discordOn"  }}>
                                <a style={{marginRight: "1px"}} href="https://preunk.com/discord"><BsDiscord /></a>
                              </IconContext.Provider>
                              
                              ) : <IconContext.Provider value={{ className: "discordOff"  }}><BsDiscord /> </IconContext.Provider>
                          }

                          {/* server modded logic  */}
                          { s.modded === 'true'
                            ? (<IconContext.Provider value={{ className: "modded"  }}>< MdOutlineBuild /></IconContext.Provider>) :
                              (<IconContext.Provider value={{ className: "unmodded"  }}>< MdOutlineBuild /></IconContext.Provider>)
                          }

                          {/* mUrl logic */}
                          {/* {s.name.includes('ugc/') ? (
                            <IconContext.Provider value={{ className: "selected"  }}>
                              <a href={"https://ohdservers.net/mods/" + s.name.split("ugc/")[1].split(" ")[0]} target="_new"><BsPuzzle /></a>
                            </IconContext.Provider>
                          ) : <IconContext.Provider value={{ className: "unselected"  }}><BsPuzzle /> </IconContext.Provider>
                          } */}

                          {/* Server whitelist logic */}
                          { s.whitelist === 1
                            ? (<IconContext.Provider value={{ className: "selected" }}> <MdOutlineRememberMe /> </IconContext.Provider>) :
                              (<IconContext.Provider value={{ className: "unselected" }}> <MdOutlineRememberMe /> </IconContext.Provider>)
                          }

                          {/* Server OHD supporters logic */}
                          { s.supporter === 'true'
                            ? (<IconContext.Provider value={{ className: "selected" }}> <MdOutlineSupport /> </IconContext.Provider>) :
                              (<IconContext.Provider value={{ className: "unselected" }}> <MdOutlineSupport /> </IconContext.Provider>)
                          }

                          {s.password  === "false" ? <GrUnlock /> : <GrLock />}
             
                        </Col>
                        <Col xl="1" md="1" xs="4">{s.players} / {s.max_players}</Col>
                        <Col xl="4" md="5" xs="8" className="connect-hover-col">
             
                          <Col xs="auto" className="serverValue serverJoinLink block d-lg-block">
                            <span className="no-hover"><a href={getSteamConnect(s.sip,s.port)}><Flag code={ s.scountry } height="16" /> {s.name}</a></span><span className="connect-hover"><a className="crosshair" style={{opacity: '1', backgroundColor: 'rgba(0,0,0,0.0)'}} href={getSteamConnect(s.sip,s.port)}><Flag code={ s.scountry } height="16" /> {s.name}</a></span>
                          </Col>
                        </Col>
                        <Col xl="2" md="2" className="d-none d-md-block">{s.map}</Col>
                        <Col xl="2" md="2" className="d-none d-md-block">{s.gamemode}</Col>
                        {/* <Col md="2" className="d-none d-md-block"><Modals topic={s.map}></Modals></Col> */}
                        <Col xl="1" className="d-none d-xl-block">{s.keywords.split(",", 1)[0].split(":")[1]}</Col>

                     </Row>
                ))
            ) : ( 
                  <>
                    <h3>Looking for servers...</h3>
                    <PacmanLoader color="grey" loading={loadinganim} cssOverride={override} size={35} />
                  </>
            )}
          
      </div>
      {/* <TickerList /> */}
      </>
  );
}


export default Empty;