import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Ticker from 'react-ticker'



function isEven(n) {
  if (n % 2 === 0) {
    return { 
    "color": "black",
    "background": "lightgrey",
    "margin": "0px",
    "padding": "0px"
    }
  } else {
    return {
      "color": 'black',
      "margin": "0px",
      "padding": "0px"
     }
  }
}

function PlayerComponent(props) {
    const [topplayers, settopplayers] = useState();
    const [serverconfig, setserverconfig] = useState('');
    
    const fetchData = async () => {
    fetch('https://api.ohdservers.net/api/players?q=' + props.b64)
    //fetch('http://localhost:4848/api/ohdglobal')
        .then(response => response.json())
        .then(resultData => {
          setserverconfig(resultData);
          const TopPlayers = resultData.Players.filter(r => r.score >= 250);
          settopplayers(TopPlayers);  
        })
        .catch(error => ({ "state": "error", "msg": "Server info could not be retrieved." }))
    }

    useEffect(() => {
    fetchData();
    }, [])

    useEffect(() => {
    const interval = setInterval(() => {
        fetchData();
    }, 30000);
    return () => clearInterval(interval);
    }, [])

    //   const Players = [
    // {'index': 0, 'name': '=DRK= Bluedrake42', 'score': 900, 'duration': 607.39453125}, 
    // {'index': 0, 'name': 'Spec', 'score': 100, 'duration': 67.61869812011719},
    // {'index': 0, 'name': 'kaiz', 'score': 400, 'duration': 320.812011719},
    // ];

    // const Players = [];
    // for (let i = 0; i <30; i++) {
    //   Players.push({'index': 0, 'name': 'tester', 'score': 201, 'duration': 502});
    // }

    return(
        <>
          {serverconfig && serverconfig.state !== "error" && serverconfig.Players.length > 0  ? (
            <>
              <Row style={{margin: '0px', padding: '0px'}}>
                <Col className="playerHeader" xs="7" md="3"><h5>Soldier</h5></Col>
                <Col className="playerHeader d-none d-md-block" md="3"><h5>Score</h5></Col>
                <Col className="playerHeader" xs="5" md="6"><h5>Online</h5></Col>
              </Row>
            </>
          ) : (
              <></>
          )}
      
          {serverconfig && serverconfig.state !== "error" && serverconfig.Players.length > 0 ? (
            serverconfig.Players.map((p,index) => (
                <Row className="playerResults" key={index} style={isEven(index+1)}>
                  <Col xs="7" md="3">{
                      p.score >= 250 ? <div className="playerHighscore"> {p.name} </div> :
                      p.name
                      }
                  </Col>
                  <Col md="3" className="d-none d-md-block">{p.score}</Col>      
                  <Col xs="5" md="6">{(p.duration/60).toString().split('.')[0]} min.</Col>
                </Row>
            ))
          ) : (
              <></>
          )}
          <br />
  
          <footer className="fixed-bottom">
          <br />
          <div className="container">
            <Ticker className="ticker" mode="smooth">
              {({ index }) => (
                <>
                  {serverconfig && serverconfig.state !== "error" && topplayers ? (
                    topplayers.map((p,index) => (
                        <span className="promo" key={index}>{p.name}</span>
                    ))
                ) : (
                      <span className="promo">Welcome to ohdservers.net</span>
                )}
                </>
              )}
            </Ticker>
          </div>
         </footer>
        </>
    )

};

export default PlayerComponent;